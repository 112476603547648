import { useEffect } from 'react';
import { AorRetailer, Retailer } from 'types/retailer';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import {
  DIPLOMAT_RETAILERS_ENDPOINT,
  RETAILER_LOCATIONS_ENDPOINT,
} from 'src/constants/gql';
import { Capabilities, StoreContextValue } from 'src/providers';
import { AutocompleteResponseError } from 'src/api-helpers/google-maps-api';
import { formUrl } from 'src/utils/formUrl';
import { useFetch } from '.';
import { useSelectorSettings, useStore } from './useStore';

export type RetailerResponse = {
  data?: Retailer[];
  aorRetailers?: AorRetailer[];
  error?: string;
  status: number;
};

export function useFetchRetailers({
  sessiontoken,
  refreshSessiontoken,
  capabilities,
  initialRetailers,
}: {
  sessiontoken: StoreContextValue['sessiontoken'];
  refreshSessiontoken: any;
  capabilities: Capabilities;
  initialRetailers: boolean;
}) {
  const { regionCode, siteSlug } = useCurrentMarketSite();
  const { dispatch } = useStore();

  const selectorSettings = useSelectorSettings();
  const capabilities_array = selectorSettings.useSelector
    ? [...capabilities.SELECTOR_CAPS]
    : [...capabilities.RETAILER_CAPS, ...capabilities.SERVICE_CAPS];
  const isIntl = regionCode === '001';

  const url = isIntl
    ? formUrl(DIPLOMAT_RETAILERS_ENDPOINT, {
        siteSlug,
        sessiontoken,
        limit: 20,
        within: 300,
      })
    : formUrl(RETAILER_LOCATIONS_ENDPOINT, {
        siteSlug,
        sessiontoken,
        limit: 20,
        capabilities: capabilities_array,
        within: 300,
      });

  const {
    data: freshRetailers,
    isLoading,
    error,
  } = useFetch<RetailerResponse, AutocompleteResponseError>(
    !initialRetailers ? url : null,
    [capabilities],
  );

  useEffect(() => {
    freshRetailers?.data &&
      dispatch({ type: 'SET_RETAILERSLIST_VISIBLE', payload: true }); // Maybe remove this dispatsh
    freshRetailers?.data && refreshSessiontoken && refreshSessiontoken();
  }, [refreshSessiontoken, dispatch, freshRetailers?.data]);

  return {
    data: freshRetailers?.data || null,
    isLoading,
    error,
  };
}
