import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AorRetailer, Retailer } from 'types/retailer';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { CAPABILITIES } from 'src/constants/retailerCapabilities';
import { useFetchRetailers, useStore } from 'src/hooks';
import useAorRetailers from 'src/hooks/useAorRetailers';
import { useSortRetailers } from 'src/hooks/useSortRetailers';
import useCoordinates from 'src/hooks/useCoordinates';

const isIE =
  typeof window !== 'undefined' && (window.document as any).documentMode;

type RetailersProviderProps = React.PropsWithChildren<{
  initialRetailers: Retailer[];
}>;
export const RetailersProvider = ({
  children,
  initialRetailers,
}: RetailersProviderProps): JSX.Element => {
  initialRetailers = isIE ? [] : initialRetailers;
  const [retailers, setRetailers] = useState<Retailer[]>(initialRetailers);
  const { siteSlug } = useCurrentMarketSite();
  const { sessiontoken, dispatch, address } = useStore();
  const [capabilities, setCapabilities] = useState<Capabilities>({
    RETAILER_CAPS: CAPABILITIES['RETAILER_CAPS'],
    SERVICE_CAPS: CAPABILITIES['SERVICE_CAPS'],
    SELECTOR_CAPS: CAPABILITIES['SELECTOR_PREORDER_CAPS'],
  });

  const place_id = address?.place_id;
  const refreshSessiontoken = useCallback(() => {
    dispatch({ type: 'REFRESH_SESSION_TOKEN' });
  }, [dispatch]);

  const capabilitiesToggles = useMemo(
    () => [
      !!capabilities.RETAILER_CAPS.length && 'retailer',
      !!capabilities.SERVICE_CAPS.length && 'service',
    ],
    [capabilities],
  );

  // Get retailers
  const {
    data: freshRetailers,
    isLoading: retailersIsLoading,
    error: retailersError,
  } = useFetchRetailers({
    capabilities,
    sessiontoken,
    refreshSessiontoken,
    initialRetailers: !!initialRetailers?.length,
  });

  // TODO: will both place_id exist or should it only be one of them? What is the case if both exist? Then decide priority
  // There Should only be one - log error and treat it otherwise.
  const {
    data: aorData,
    isLoading: aorIsLoading,
    error: aorError,
  } = useAorRetailers({
    siteSlug,
    sessiontoken,
    place_id,
    coordinates: address?.coords,
  });

  const aorRetailers = aorData?.aorRetailers;
  const aorCoordinates = aorData?.coordinates;

  useEffect(() => {
    setRetailers(freshRetailers || initialRetailers);
  }, [freshRetailers, initialRetailers]);

  const {
    coordinates,
    error: coordinatesError,
    isLoading: coordinatesIsLoading,
  } = useCoordinates({
    coordinates: aorCoordinates || address?.coords,
    place_id: address?.place_id,
    sessiontoken,
  });

  // Sort by distance and set aorRetailers at top
  const sortedRetailers = useSortRetailers({
    capabilitiesToggles,
    coordinates,
    refreshSessiontoken,
    retailers,
    aorRetailers,
  });

  const isLoading = retailersIsLoading || aorIsLoading || coordinatesIsLoading;
  const error = retailersError || aorError || coordinatesError;

  const retailersValue = useMemo(
    () => ({
      setCapabilities,
      capabilitiesToggles,
      retailers: sortedRetailers,
      isLoading,
      error,
      aorRetailers,
    }),
    [capabilitiesToggles, sortedRetailers, isLoading, error, aorRetailers],
  );

  return (
    <RetailersContext.Provider value={retailersValue}>
      {children}
    </RetailersContext.Provider>
  );
};

export type RetailerResponse = {
  data?: Retailer[];
  aorRetailers?: AorRetailer[];
  error?: string;
  status: number;
};

export type Capabilities = {
  RETAILER_CAPS: string[];
  SERVICE_CAPS: string[];
  SELECTOR_CAPS: string[];
};
export type RetailersContextValue = {
  setCapabilities: Dispatch<SetStateAction<Capabilities>> | null;
  capabilitiesToggles: (string | false)[];
  retailers?: Retailer[];
  isLoading: boolean;
  error: any;
  aorRetailers: AorRetailer[] | undefined;
};
export const RetailersContext = createContext<RetailersContextValue>({
  setCapabilities: null,
  capabilitiesToggles: [false, false],
  retailers: undefined,
  isLoading: false,
  error: null,
  aorRetailers: [],
});
