import { useEffect } from 'react';
import {
  LatLngLiteral,
  LatLngLiteralVerbose,
} from '@googlemaps/google-maps-services-js';
import memoize from 'lodash/memoize';
import { AorRetailer, Retailer } from 'types/retailer';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { getDistance } from 'src/utils/getDistance';
import { isValidLatitudeLongitude } from 'src/utils/validations';
import { getReorderedRetailers } from '../utils/getReorderedRetailers';
import { useStore } from './useStore';

type SortRetailersProps = {
  retailers: Retailer[];
  coordinates: LatLngLiteralVerbose | undefined;
  refreshSessiontoken: any;
  capabilitiesToggles: (string | false)[];
  aorRetailers?: AorRetailer[];
};

export function useSortRetailers({
  coordinates,
  capabilitiesToggles,
  retailers,
  aorRetailers,
}: SortRetailersProps): Retailer[] {
  const { roadLengthUnit } = useCurrentMarketSite();
  const { dispatch } = useStore();

  // If we have new retailers or placeId change, remove the selected retailer
  useEffect(() => {
    !!retailers.length &&
      dispatch({ type: 'SET_SELECTED_RETAILER', payload: null });
  }, [coordinates, retailers, dispatch]);

  // When we get coordinates, start showing retailer-list.
  useEffect(() => {
    coordinates &&
      dispatch({ type: 'SET_RETAILERSLIST_VISIBLE', payload: true });
  }, [coordinates, dispatch]);

  const isValidCoordinates =
    coordinates && isValidLatitudeLongitude(coordinates);
  const earthRadius = roadLengthUnit === 'mile' ? 3958.8 : 6371; // earths radius in km/miles
  const sortedRetailers = retailers.length
    ? sortRetailers(
        retailers,
        isValidCoordinates
          ? {
              lng: coordinates.longitude,
              lat: coordinates.latitude,
            }
          : undefined,
        capabilitiesToggles,
        earthRadius,
        aorRetailers?.length ? aorRetailers[0].parmaPartnerCode : null,
      )
    : [];

  return sortedRetailers;
}

export const sortRetailers = memoize(
  function sortRetailers(
    retailers: Retailer[],
    coordinates: LatLngLiteral | undefined,
    capabilitiesToggles: (string | false)[],
    radius: number,
    priorityParmaPartnerCode?: string | null | undefined,
  ) {
    const origin: [number, number] = [
      coordinates?.lat || 0,
      coordinates?.lng || 0,
    ];
    retailers =
      capabilitiesToggles?.every((c) => c === false) ||
      capabilitiesToggles?.every((c) => c === 'retailer' || c === 'service')
        ? retailers
        : retailers.filter((retailer) => {
            return retailer.capabilities?.some(
              (c, i) => c === capabilitiesToggles[i],
            );
          });
    retailers = retailers
      .map((retailer) => {
        if (!retailer.latitude || !retailer.longitude || !coordinates?.lat)
          return { ...retailer };
        const distanceValue = getDistance(
          origin,
          [parseFloat(retailer.latitude), parseFloat(retailer.longitude)],
          radius,
        );
        const distanceFromPointKm = distanceValue;
        return {
          ...retailer,
          distanceFromPointMiles: distanceFromPointKm * 1.60934,
          distanceFromPointKm,
        };
      })
      .sort((a, b) => {
        return a.distanceFromPointKm - b.distanceFromPointKm;
      });

    if (!priorityParmaPartnerCode) return retailers;

    return getReorderedRetailers(
      retailers,
      priorityParmaPartnerCode,
      (r) => r.parmaPartnerCode,
    );
  },
  (unused_1, b, c, unused_2, e) => JSON.stringify({ ...b, ...c, e }),
);
