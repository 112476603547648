import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';

export type SearchInputContextValue = {
  inputRef: React.RefObject<HTMLInputElement> | null;
  input: {
    value: string;
    skipFetch: boolean;
    resetPredictions: boolean;
  };
  setInput: Dispatch<SetStateAction<SearchInputContextValue['input']>>;
};
export const SearchInputContext = createContext<SearchInputContextValue>({
  inputRef: null,
  input: {
    value: '',
    skipFetch: false,
    resetPredictions: false,
  },
  setInput: () => {},
});

type SearchInputProviderProps = React.PropsWithChildren<{
  initialSearchValue?: string;
}>;

export const SearchInputProvider = ({
  children,
  initialSearchValue,
}: SearchInputProviderProps): JSX.Element => {
  const { push, query } = useRouter();
  const [input, setInput] = useState({
    value: initialSearchValue ?? '',
    skipFetch: false,
    resetPredictions: false,
  });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { searchQuery: oldSearchQuery, ...queryParams } = query;
    const searchQuery = input.value && { searchQuery: input.value };

    push({ query: { ...queryParams, ...searchQuery } }, undefined, {
      shallow: true,
    });
    // If including query, it slows down due to retriggering twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const value = useMemo(
    () => ({
      inputRef,
      input,
      setInput,
    }),
    [inputRef, input],
  );

  return (
    <SearchInputContext.Provider value={value}>
      {children}
    </SearchInputContext.Provider>
  );
};
