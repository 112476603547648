import { useTracker } from '@volvo-cars/tracking';
import { TRACKING_CATEGORY } from 'src/constants/tracking';
import { useSearchInput, useStore, useTranslate } from 'src/hooks';

const navigatorSettings = {
  timeout: Infinity,
  enableHighAccuracy: true,
  maximumAge: 0,
};

type UseUserLocationProps = { setIsLoading?: (isLoading: boolean) => void };

export const useUserLocation = ({ setIsLoading }: UseUserLocationProps) => {
  const { dispatch } = useStore();
  const translate = useTranslate();
  const { inputRef, setInput } = useSearchInput();
  const track = useTracker();

  const navigatorCallback = ({ coords }: GeolocationPosition) => {
    dispatch({
      type: 'SET_ADDRESS',
      payload: {
        place_id: '',
        description: '',
        matched_substrings: [],
        structured_formatting: {} as any,
        coords: {
          longitude: coords.longitude,
          latitude: coords.latitude,
        },
      },
    });
    setInput({
      value: translate('search.form.myLocation') || 'My location',
      skipFetch: true,
      resetPredictions: true,
    });
    inputRef?.current && inputRef?.current.blur();
    setIsLoading && setIsLoading(false);
  };
  const handleClick = () => {
    track.customEvent({
      eventAction: 'button|click',
      eventCategory: TRACKING_CATEGORY,
      eventLabel: 'use my location',
    });
    if (!navigator || !navigator.geolocation) return;
    setIsLoading && setIsLoading(true);
    navigator.geolocation.getCurrentPosition(
      navigatorCallback,
      (err) => console.warn('Error getting user location', err),
      navigatorSettings,
    );
  };

  return {
    handleClick,
  };
};
